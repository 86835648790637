<template>
    <div class="w-100">
        <!-- Header @start -->
        <Header />
        <!-- Header @end -->

        <!-- Back Button @start-->
        <b-row class="w-100 mt-6 px-3 is-mobile">
            <b-col cols="12" class="mb-3 zc-text-format">
                <!-- arrow-left.svg -->
                <div @click="$router.push({ path: '/' })" style="width: fit-content;" class="go-back">
                    <img src="@/assets/zaajira-candidate/assets/icons/arrow-left.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <span style="font-size: 1.2rem;" class="pl-3">
                        <!-- {{ isDesktop ? $gettext("Search") : $gettext("Job_detail") }} -->
                        {{ $gettext("Search") }}
                    </span>
                </div>
            </b-col>
        </b-row>
        <!-- Back Button @end-->

        <!-- Search and Search Result @start -->
        <b-row class="px-3 m-0 zc-mt-lg-6-md-0">

            <!-- Related jobs @start -->
            <b-col v-if="userSearchJobs.length && isSearch" class="search-bg div-order-2" cols="12" col lg="3" md="12"
                sm="12">
                <div style="width: 100%;height: 3.25rem;" class=" d-flex justify-content-between align-items-center">
                    <span style="font-size: 1rem;" class="search-txt">{{ $gettext("Search_result") }}</span>
                    <span style="font-size: .875rem; opacity: .8;" class="search-txt">
                        {{ userSearchJobs.length }} {{ $gettext("Jobs_found") }}</span>
                </div>
            </b-col>
            <b-col cols="12" col :lg="userSearchJobs.length && isSearch ? 9 : 12" md="12" sm="12"
                class="d-flex align-item-center">

                <b-form @submit.prevent="jobSearch" class="customized-form d-flex w-100">
                    <img style="position:absolute; top: 16px;left: 20px;"
                        src="@/assets/zaajira-candidate/assets/icons/search-normal.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <b-form-input
                        style=" margin-bottom:0;padding-left: 2rem;color: var(--black,#000); background-color: #fff;"
                        class="form-control zc-search-input" size="sm" v-model="search"
                        :placeholder='$gettext("Search_by_title_company_or_keywords")'></b-form-input>
                    <img id="filter-btn1" @click="openBottomSheet"
                        style="cursor: pointer; position:absolute; top: 14px;right: 30px;"
                        src="@/assets/zaajira-candidate/assets/icons/ion_filter.svg" width="25px" height="25px"
                        class="img-fluid is-mobile" />
                </b-form>

            </b-col>
        </b-row>
        <!-- Search and Search Result @end -->

        <b-row class="p-3 m-0 mob-flex-reverse">
            <!-- Related jobs @start -->
            <b-col col lg="3" md="12" sm="12">
                <div class="zc-container">
                    <b-row style="margin: 0;">
                        <b-col cols="12">
                            <h1 class="zc-text-format mb-0 mt-2">{{ $gettext("Related_jobs") }}</h1>
                            <img src="@/assets/zaajira-candidate/assets/icons/divider.svg" width="100%" height="auto"
                                class="img-fluid" />
                        </b-col>
                        <b-col cols="12">
                            <b-row v-for="(s, i) in userSearchJobs " :key="i" class="my-2">
                                <b-col @click="goBack(s.job_id)" cols="9">
                                    <div>
                                        <div class="d-flex">
                                            <b-avatar size="3rem" style="background: #F1F1F1;" rounded class="mr-2">
                                                <!-- <img src="@/assets/zaajira-candidate/assets/company-logo/amazon.svg"
                                                    width="60%" height="auto" class="img-fluid" /> -->
                                                <img :src="s.company_logo_path ? `${IMAGEURL}${s.company_logo_path}` : avtar"
                                                    width="60%" height="auto" class="img-fluid" />
                                            </b-avatar>
                                            <div class="d-flex flex-column justify-content-between">
                                                <span style="cursor: pointer; font-size:.875rem ;" class="h-job-name">{{
                    s.Job_title }}</span>
                                                <div style="gap: 10px;" class="d-flex">
                                                    <span style="font-size: 0.75rem; opacity: .3;" class="h-company">
                                                        {{ s.company }}
                                                        • {{ s.postedAt }}
                                                        ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="3" class="text-right">
                                    <!-- style="display: flex;flex-direction: column;justify-content: space-between;min-height: 2.8rem;" -->
                                    <div class="d-flex flex-column justify-content-between" style="min-height: 2.8rem;">
                                        <div style="text-wrap: nowrap; opacity: .5;" class="zc-job-format">
                                            {{ s.jobType }}
                                        </div>
                                        <!-- <div style="text-wrap: nowrap; opacity: .5;" class="zc-job-format">
                                            Expert
                                        </div> -->
                                    </div>
                                </b-col>
                                <img src="@/assets/zaajira-candidate/assets/icons/divider.svg" width="100%"
                                    height="auto" class="img-fluid mt-2" />
                            </b-row>

                        </b-col>
                    </b-row>
                </div>

            </b-col>
            <!-- Related jobs @end -->
            <!-- job details @start -->
            <b-col lg="9" md="12" sm="12">
                <div class="zc-container">
                    <img src="@/assets/zaajira-candidate/assets/bg-images/job-details-cover.png"
                        style="width:100%;height: 3.3125rem;" class="img-fluid" />
                    <b-row>
                        <b-col lg="8" md="12" sm="12">

                            <b-row class="p-3">
                                <b-col lg="8" md="12" sm="12" cols="12">
                                    <b-row style="gap: 10px;flex-wrap: nowrap; padding-left: 1rem;">
                                        <div cols="3">
                                            <b-avatar style="background: #F1F1F1;" rounded>
                                                <img v-if="userJobs[0].company_logo_path !== undefined"
                                                    :src="userJobs[0].company_logo_path ? `${IMAGEURL}${userJobs[0].company_logo_path}` : avtar"
                                                    width="75%" height="auto" class="img-fluid" />
                                            </b-avatar>
                                        </div>
                                        <div style="padding: 0 0 0 0; margin-top: -4px;" cols="8">
                                            <span style="opacity: .8;" class="h-job-name">
                                                {{ userJobs[0].Job_title ? userJobs[0].Job_title : '' }}
                                            </span>
                                            <div style="gap: 10px;" class="d-flex">
                                                <div class="h-company">
                                                    {{ userJobs[0].company ? userJobs[0].company : '' }}
                                                    <img src="@/assets/zaajira-candidate/assets/icons/varified_tick.svg"
                                                        width="15px" height="auto" class="img-fluid" />
                                                </div>
                                            </div>
                                            <div class="is-desktop zc-job-format mt-2" style=" font-weight: 600;">
                                                <!-- TZS 700,000 – 900,000/Month -->
                                                {{ userJobs[0].currency ? userJobs[0].currency : '' }}
                                                {{ userJobs[0].job_salary_range_from ? userJobs[0].job_salary_range_from
                    :
                    ''
                                                }} -
                                                {{ userJobs[0].job_salary_range_to ? userJobs[0].job_salary_range_to :
                    ''
                                                }}/Month
                                            </div>
                                            <div class="is-desktop zc-job-format mt-2" style=" font-weight: 600;">
                                                {{ $gettext("Industry") }}: {{ userJobs[0].speciality[0].speciality }}
                                            </div>
                                        </div>
                                    </b-row>
                                </b-col>
                                <b-col lg="4" md="12" sm="12" cols="12" class="zc-job-left mt-2 zc-t-mleft-dright">
                                    <div style="opacity: .3;" class="mt-2 zc-job-format zc-ml-4">
                                        <!-- Posted 2 days ago -->
                                        {{ $gettext("Posted") }} {{ userJobs[0].postedAt ? userJobs[0].postedAt : '' }}
                                    </div>
                                    <div class="is-mobile mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="5"
                                            height="5" viewBox="0 0 2 2" fill="none">
                                            <circle cx="1" cy="1" r="1" fill="#2D68B2" />
                                        </svg></div>
                                    <div class="zc-job-format my-2 zc-mt-1" style="color: #2D68B2; font-weight: 500;">
                                        {{ userJobs[0].total_applied ? `${userJobs[0].total_applied}
                                        ${$gettext("applicant")}` : '' }}
                                    </div>
                                    <div class="is-desktop zc-job-format" style="font-weight: 600; color: #FF9900;">
                                        {{ userJobs[0].numberOfOpenings ? `${userJobs[0].numberOfOpenings}
                                        ${$gettext("Opening")}` : '' }}
                                    </div>
                                </b-col>

                                <b-col lg="12" style="margin-left: -14px;" class="job-op-sal is-mobile">
                                    <div class="zc-job-c-format" style="font-weight: 600;margin-left: 4rem;">
                                        <!-- TZS 700,000 – 900,000/Month -->
                                        {{ userJobs[0].currency ? userJobs[0].currency : '' }}
                                        {{ userJobs[0].job_salary_range_from ? userJobs[0].job_salary_range_from :
                    ''
                                        }} -
                                        {{ userJobs[0].job_salary_range_to ? userJobs[0].job_salary_range_to : ''
                                        }}/Month
                                        <br>
                                        {{ $gettext("Industry") }}: {{ userJobs[0].speciality[0].speciality }}
                                    </div>

                                    <div class="zc-job-format"
                                        style=" font-weight: 600;margin-top: .4rem; margin-left: 4rem; color: #FF9900;">
                                        {{ userJobs[0].numberOfOpenings ? `${userJobs[0].numberOfOpenings}
                                        ${$gettext("Opening")}` : '' }}
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="my-2 ml-lg-2" style="width: 100%; height: 0px; border: 1px #E9E6E6 solid"></div>
                            <b-row class="ml-1 p-1">
                                <b-col>
                                    <div class="zc-job-header">
                                        {{ $gettext("Description") }}
                                    </div>
                                    <p style="opacity: .5;" class="zc-job-format">
                                        <!-- We are looking for a talented UI Designer to create amazing user experiences. The
                                        ideal
                                        candidate should have an eye for clean and artful design, possess superior UI
                                        skills. -->
                                        {{ userJobs[0].jobDescription ? userJobs[0].jobDescription : "" }}
                                    </p>
                                    <div v-if="userJobs[0].education.length" class="zc-job-header ">
                                        {{ $gettext("Education") }}
                                    </div>
                                    <ul v-if="userJobs[0].education.length" style="opacity: .5; list-style: inside;"
                                        class="zc-job-format">
                                        <li v-for="(r, i) in userJobs[0].education" :key="i">
                                            {{ r.job_education }}
                                        </li>
                                    </ul>
                                    <div class="zc-job-header">
                                        {{ $gettext("Responsibilities") }}
                                    </div>
                                    <ul style="opacity: .5; list-style: inside;" class="zc-job-format">
                                        <li v-for="(r, i) in userJobs[0].responsibilities" :key="i">
                                            {{ r.job_responsibility }}
                                        </li>
                                    </ul>
                                    <div class="zc-job-header">
                                        {{ $gettext("Skills") }}
                                    </div>
                                    <div class="ml-5">
                                        <!-- <div style="color: var(--checkbox-blue); font-size: 1rem;" class="zc-job-format">
                                            Skills
                                        </div> -->

                                        <ul style="font-size:0.875; opacity: .5;" class="zc-job-format">
                                            <li v-for="(r, i) in userJobs[0].skills" :key="i">
                                                {{ r }}
                                            </li>
                                        </ul>

                                        <!-- <div style="color: var(--checkbox-blue); font-size: 1rem;" class="zc-job-format">
                                            Experience
                                        </div>
                                        <ul style="font-size:0.875; opacity: .5;" class="zc-job-format">
                                            <li> {{ userJobs[0].job_experiance ? `${userJobs[0].job_experiance} Years` : ""
                                            }}</li>
                                        </ul> -->
                                    </div>
                                    <div class="zc-job-header">
                                        {{ $gettext("Experience") }}
                                    </div>
                                    <ul style="opacity: .5; list-style: inside;" class="zc-job-format">
                                        <li>
                                            {{ userJobs[0].job_experiance ? `${userJobs[0].job_experiance} Years` : ""
                                            }}
                                        </li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col lg="4" md="12" sm="12" class="pl-4 zc-border-left">
                            <div class="mt-3">
                                <div class="zc-job-header">
                                    {{ $gettext("Address") }}
                                </div>
                                <div style="opacity: .5;" class="zc-job-format">
                                    {{ userJobs[0].country ? userJobs[0].country : "" }}, {{ userJobs[0].city ?
                    userJobs[0].city : "" }}
                                </div>
                                <div class="pr-3 pt-4">
                                    <b-button :disabled="userJobs[0].isApplied"
                                        @click="NavigateToInterview(userJobs[0].job_id)"
                                        class="btn full-width-btn btn-text text-uppercase" variant="primary" size="sm">
                                        {{ userJobs[0].isApplied ? $gettext("Applied") : $gettext("Apply") }}
                                        <!-- <router-link to="personal-details">Create visiting card</router-link> -->
                                    </b-button>
                                    <b-button @click="saveJob(userJobs[0].job_id)"
                                        class="btn full-width-btn btn-text mt-2" variant="secondary" size="sm">
                                        {{ userJobs[0].isSaved ? $gettext("Saved") : $gettext("Save_Job") }}
                                    </b-button>
                                    <b-button @click="share(userJobs[0])" class="btn full-width-btn btn-text mt-2"
                                        variant="info" size="sm">
                                        {{ $gettext("Share_Job") }}
                                    </b-button>
                                </div>
                                <b-row class="my-2 divider divider-mt"></b-row>
                                <div>
                                    <div class="zc-job-header">{{ $gettext("Other_information") }}</div>
                                    <p style="opacity: .8;" class="zc-job-format mt-3">{{ userJobs[0].numberOfOpenings
                    ? userJobs[0].numberOfOpenings : '' }}
                                        {{ $gettext("Openings") }}</p>
                                    <div>
                                        <p style="opacity: .8; margin-bottom: 0;" class="zc-job-format">
                                            {{ $gettext("Job_Type") }}
                                        </p>
                                        <p style="opacity: .5;" class="zc-job-format">
                                            {{ userJobs[0].jobType ? userJobs[0].jobType : '' }}
                                        </p>
                                    </div>
                                    <div>
                                        <p style="opacity: .8; margin-bottom: 0;" class="zc-job-format">
                                            {{ $gettext("Gender") }}
                                        </p>
                                        <p style="opacity: .5;" class="zc-job-format">
                                            {{ userJobs[0].gender ? userJobs[0].gender : '' }}
                                        </p>
                                    </div>
                                    <div>
                                        <p style="opacity: .8; margin-bottom: 0;" class="zc-job-format">
                                            {{ $gettext("Nationality") }}
                                        </p>
                                        <p style="opacity: .5;" class="zc-job-format">
                                            <!-- {{ userJobs[0].nationality ? userJobs[0].nationality : '' }} -->
                                            <span v-for="(n, i) in userJobs[0].nationality" :key="i">
                                                {{ n.nationality }},
                                            </span>

                                        </p>
                                    </div>
                                    <div v-if="userJobs[0].education.length">
                                        <p style="opacity: .8; margin-bottom: 0;" class="zc-job-format">
                                            {{ $gettext("Minimum_Education") }}
                                        </p>
                                        <p style="opacity: .5;" class="zc-job-format">
                                            <!-- Graduation -->
                                        <ul style="opacity: .8; margin-bottom: 0;list-style: inside;"
                                            v-for="(r, i) in userJobs[0].education" :key="i">
                                            <li>
                                                {{ r.job_education }}
                                            </li>
                                        </ul>
                                        </p>
                                    </div>
                                    <div v-if="userJobs[0].job_experiance !== null">
                                        <p style="opacity: .8; margin-bottom: 0;" class="zc-job-format">
                                            {{ $gettext("Experience") }}
                                        </p>
                                        <p style="opacity: .5;" class="zc-job-format">
                                            <!-- Min. 1 year -->
                                            <span> {{ userJobs[0].job_experiance ? `Min.
                                                ${userJobs[0].job_experiance.split('-')[0]} year`
                    : ""
                                                }}</span>
                                        </p>
                                    </div>
                                    <div v-if="userJobs[0].currency !== null">
                                        <p style="opacity: .8; margin-bottom: 0;" class="zc-job-format">
                                            {{ $gettext("Salary") }}
                                        </p>
                                        <p style="opacity: .5;" class="zc-job-format">
                                            <!-- TZS 800,000 - 1,000,00/M -->
                                            {{ userJobs[0].currency ? userJobs[0].currency : '' }}
                                            {{ userJobs[0].job_salary_range_from ? userJobs[0].job_salary_range_from :
                    ''
                                            }} -
                                            {{ userJobs[0].job_salary_range_to ? userJobs[0].job_salary_range_to : ''
                                            }}/M
                                        </p>
                                    </div>
                                    <div>
                                        <p style="opacity: .8; margin-bottom: 0;" class="zc-job-format">
                                            {{ $gettext("End_Date") }}
                                        </p>
                                        <p style="opacity: .5;" class="zc-job-format">
                                            {{ userJobs[0].job_end_date }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>

            </b-col>
            <!-- job details @end -->
        </b-row>
        <zcBottomSheet :isOpen="homeFilter" @close="closeBottomSheet">
            <div slot="child">
                <b-row class="m-0 ">
                    <b-col class="filter-container" col lg="3" md="12" sm="12">
                        <div class="filter-header mt-2 d-flex justify-content-between">
                            <span>{{ $gettext("Filters") }}</span>
                            <span style="cursor: pointer;" @click.prevent.stop="clearFilter">
                                {{ $gettext("Clear_all") }}
                                <img src="@/assets/zaajira-candidate/assets/icons/coss_gray.svg" width="19px"
                                    height="19px" class="img-fluid" />
                            </span>
                        </div>
                        <div class="py-2 text-left">
                            {{ $gettext("Country") }}
                            <multiselect class="custom-select-2" v-model="country" :options="countryOptions"
                                :searchable="false" @input="GetAllCityOfCountry(country.country_id)"
                                :close-on-select="true" :show-labels="true" label="country_name"
                                placeholder="Pick a value">
                            </multiselect>
                        </div>
                        <div class="py-2 text-left">
                            <label class="typo__label">{{ $gettext("City") }}</label>
                            <multiselect class="custom-select-2" v-model="city" :options="cityOptions"
                                :searchable="false" :close-on-select="true" :show-labels="true" label="label"
                                placeholder="Pick a value">
                            </multiselect>
                        </div>
                        <div class="py-2 text-left">
                            <label class="typo__label">{{ $gettext("Type_of_Job") }}</label>
                            <multiselect class="custom-select-2" v-model="typeOfJob" :options="typeOfJobOptions"
                                :searchable="false" :close-on-select="true" :show-labels="true" label="job_type_title"
                                placeholder="Pick a value">
                            </multiselect>
                        </div>
                        <div id="industry-jd" class="pt-2 text-left">
                            <label class="typo__label">{{ $gettext("Industry") }}</label>
                            <!-- <multiselect class="custom-select-2" v-model="city" :options="cityOptions" :searchable="false"
                        :close-on-select="true" :show-labels="true" placeholder="Pick a value"></multiselect> -->
                            <div class="ind-filter-items">
                                <b-form-group style="height: 270px;overflow: auto;" v-slot="{ ariaDescribedby }">
                                    <b-form-checkbox-group id="h-industry-filter" v-model="industry"
                                        value-field="speciality_id" text-field="speciality" :options="industryOptions"
                                        :aria-describedby="ariaDescribedby" name="industry"></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                        </div>
                    </b-col>
                    <div class="pt-2 w-100 d-flex justify-content-center align-items-center">
                        <button class="btn btn-user-profile4 btn-text-white w-100" @click="filterJobs">
                            {{ $gettext("Apply_Filter") }}
                        </button>
                    </div>
                </b-row>
            </div>
        </zcBottomSheet>

        <zcModal :is-open="isShare" @open="isShare = true" @close="isShare = false" :isCloseBTN="true"
            cumtomeStyle="position: absolute;min-width: 20rem;max-width: 26rem;width: 100%;top: 3rem;">

            <template v-slot:child>
                <div>
                    <h4>Share this job on social network</h4>
                    <div class="social-icons-grid d-flex justify-content-between">
                        <ShareNetwork network="Whatsapp"
                            :url="`${SERVERURL}candidate/job-deatils/${userJobs[0].job_id}`"
                            :title="userJobs[0].Job_title ? userJobs[0].Job_title : ''"
                            :description="userJobs[0].job_description ? userJobs[0].job_description : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-whatsapp"></i>
                            </div>
                        </ShareNetwork>
                        <ShareNetwork network="Facebook"
                            :url="`${SERVERURL}candidate/job-deatils/${userJobs[0].job_id}`"
                            :title="userJobs[0].Job_title ? userJobs[0].Job_title : ''"
                            :description="userJobs[0].job_description ? userJobs[0].job_description : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-facebook"></i>
                            </div>
                        </ShareNetwork>
                        <ShareNetwork network="Twitter" :url="`${SERVERURL}candidate/job-deatils/${userJobs[0].job_id}`"
                            :title="userJobs[0].Job_title ? userJobs[0].Job_title : ''"
                            :description="userJobs[0].job_description ? userJobs[0].job_description : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-twitter"></i>
                            </div>
                        </ShareNetwork>
                        <ShareNetwork network="Linkedin"
                            :url="`${SERVERURL}candidate/job-deatils/${userJobs[0].job_id}`"
                            :title="userJobs[0].Job_title ? userJobs[0].Job_title : ''"
                            :description="userJobs[0].job_description ? userJobs[0].job_description : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-linkedin"></i>
                            </div>
                        </ShareNetwork>
                    </div>
                </div>
            </template>
        </zcModal>

    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
import zcBottomSheet from '../../../components/Modal/BottomSheet.vue'
import zcModal from '../../../components/Modal/Modal.vue'
import Multiselect from 'vue-multiselect'
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { formattedNumber, convertToTimeSince } from '../Helper/HelperFunctions.js'
import { IMAGEURL } from "@/config";
import avtar from '@/assets/zaajira-candidate/assets/bg-images/avtar-image.png'
import { SERVERURL } from "@/config";

export default {
    name: "JobDetails",
    components: { Header, zcBottomSheet, zcModal, Multiselect },
    data() {
        return {
            avtar,
            IMAGEURL,
            SERVERURL,
            isApplied: false,
            allSavedJobs: [],
            userJobs: [
                //     {
                //     job_id: sj.job_id,
                //     company_logo_path: sj.company_logo_path,
                //     Job_title: sj.job_title,
                //     company: sj.company_long_name,
                //     jobType: sj.job_type,
                //     postedAt: "Posted " + convertToTimeSince(sj.job_start_date),
                //     total_applied: sj.total_applicant,
                //     numberOfOpenings: sj.vacancy,
                //     job_salary_range_from: formattedNumber(sj.job_salary_range_from),
                //     job_salary_range_to: formattedNumber(sj.job_salary_range_to),
                //     gender: sj.gender === "Female" ? "F" : "M",
                //     jobDescription: sj.job_description,
                //     qualification: sj.edu.length ? sj.edu : [],
                //     responsibilities: sj.resp.length ? sj.resp : [],
                //     skills: sj.skills.length ? sj.skills : [],
                //     education: sj.edu.length ? sj.edu : [],
                //     job_experiance: sj.job_experiance,
                //     // designation: "Senior level",
                //     country: sj.country[0].country_name,
                //     city: sj.city[0].city_name,
                //     currency: sj.currency_code,
                //     nationality: sj.nationality.length ? sj.nationality : []
                // }
            ],
            isDesktop: true,
            isMobileMenu: window.innerWidth <= 992,
            userSearchJobs: [],
            search: '',
            isSearch: false,
            homeFilter: false,
            country: null,
            countryOptions: [],
            city: null,
            cityOptions: [],
            industry: [],
            industryOptions: [],
            userJobCount: null,
            isShare: false,
            typeOfJob: null,
            typeOfJobOptions: [
                {
                    job_type_id: 1,
                    job_type_title: "Any",
                },
                {
                    job_type_id: 2,
                    job_type_title: "Full Time",
                },
                {
                    job_type_id: 3,
                    job_type_title: "Part Time",
                },
                {
                    job_type_id: 4,
                    job_type_title: "Remote",
                },
                {
                    job_type_id: 5,
                    job_type_title: "Internship",
                },
                {
                    job_type_id: 6,
                    job_type_title: "Contract",
                },
                {
                    job_type_id: 7,
                    job_type_title: "Work from home",
                },
            ],
        }
    },
    methods: {
        goBack(job_id) {
            //console.log("back::job_id", job_id);
            // if (this.isMobileMenu && this.isDesktop) {
            //     this.isDesktop = !this.isDesktop
            //     this.isMobileMenu = !this.isMobileMenu
            //     this.PreFilledData(job_id)
            // } else {
            //     //console.log('desk');
            //     this.isDesktop = true
            //     this.isMobileMenu = window.innerWidth <= 992

            //     this.PreFilledData(job_id)
            // }
            this.PreFilledData(job_id)
            window.scrollTo(0, 0);
        },
        async getAllJobsOrFilter(payload) {
            // CandidateService.GetAllJobsOrFilter(payload)
            //     .then((res) => {
            ////         console.log("all jobs", res.data.data.data);
            //         this.userJobCount = res.data.data.total
            //         const allJobs = res.data.data.data
            //         let filterJob = []
            //         allJobs.map(async (aj) => {
            //             const apJob = {
            //                 company_logo_path: aj.company_logo_path,
            //                 Job_title: aj.job_title,
            //                 company: aj.company_long_name,
            //                 jobType: aj.job_type,
            //                 designation: "Senior level",
            //                 country: aj.country,
            //                 city: aj.city_name,
            //                 postedAt: "Posted " + convertToTimeSince(aj.job_start_date),
            //                 jobDescription: aj.job_description,
            //                 numberOfOpenings: aj.vacancy,
            //                 currency: aj.currency_code,
            //                 job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
            //                 job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
            //                 gender: aj.gender === "Female" ? "F" : "M"
            //             }
            //             filterJob.push(apJob)
            //         })
            //         this.userJobs = filterJob
            //         loader.hide()
            //     })
            //     .catch((error) => {
            ////         console.log(error);
            //         loader.hide()
            //     })

            try {
                const res = await CandidateService.GetAllJobsOrFilter(payload)
                //console.log("all jobs", res.data.data.data);
                this.userJobCount = res.data.data.total
                const allJobs = res.data.data.data
                let filterJob = []
                allJobs.filter((aj) => aj.job_id !== this.userJobs[0].job_id).map(async (aj) => {
                    const apJob = {
                        job_id: aj.job_id,
                        company_logo_path: aj.company_logo_path,
                        Job_title: aj.job_title,
                        company: aj.company_long_name,
                        jobType: aj.job_type,
                        designation: "Senior level",
                        country: aj.country,
                        city: aj.city_name,
                        postedAt: convertToTimeSince(aj.job_created_at),
                        jobDescription: aj.job_description,
                        numberOfOpenings: aj.vacancy,
                        currency: aj.currency_code,
                        job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
                        job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
                        gender: aj.gender === "Female" ? "F" : "M"
                    }
                    filterJob.push(apJob)
                })
                console.log('this.userJobs...', this.userJobs[0].job_id);
                this.userSearchJobs = filterJob

            } catch (error) {
                //console.log(error);

            }
        },
        async clearFilter() {
            this.country = null
            this.city = null
            this.industry = []
            const payload = {
                role_id: null,
                user_id: null,
                currentpage: 1,
                perPage: 10,
                search: this.search,
                filter: { filter: { filters: [] } }
            }
            await this.getAllJobsOrFilter(payload)
        },

        async filterJobs() {
            //// console.log("country", this.country);
            //// console.log("City", this.city);
            //console.log("industry", this.industry);
            let f = []

            if (this.industryOptions.length) {
                const filterIndustry = this.industryOptions.filter((ind) => this.industry.includes(ind.speciality_id)).map((ind) => {
                    //console.log("ind", ind);
                    const indFilter = {
                        "checkbox": ind.speciality,
                        "checkbox_id": ind.speciality_id,
                        "title": "industry"
                    }
                    f.push(indFilter)

                })
            }
            if (this.country) {
                f.push({ "checkbox": this.country?.country_name, "checkbox_id": this.country?.country_id, "title": "Country" })
            }
            if (this.country && this.city) {
                // f.push({ "checkbox": this.country?.country_name, "checkbox_id": this.country?.country_id, "title": "Country" })
                f.push({ "city_id": this.city?.city_id, "city_name": this.city?.label, "country_name": this.country?.country_name })
            }
            if (this.typeOfJob !== null) {
                // f.push({ "checkbox": this.country?.country_name, "checkbox_id": this.country?.country_id, "title": "Country" })
                f.push({ "job_type": this.typeOfJob.job_type_title, "title": "job_type", "checkbox_id": this.typeOfJob.job_type_title })
            }
            //console.log("FilterPayload", f);
            const payload = {
                role_id: null,
                user_id: null,
                currentpage: 1,
                perPage: 10,
                search: '',
                filter: { filter: { filters: f } }
            }
            await this.getAllJobsOrFilter(payload)
            this.homeFilter = false
            this.isDesktop = true
            this.isMobileMenu = window.innerWidth <= 992

        },
        async jobSearch(s) {
            event.preventDefault();
            //console.log("search", s);
            //console.log("search", this.search);
            // this.search = s.target.value

            if (this.search === '' && this.userJobs.length) {
                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: 1,
                    perPage: 10,
                    search: '',
                    filter: {
                        filter: {
                            filters: [
                                {
                                    "checkbox": this.userJobs[0].speciality[0].speciality,
                                    "checkbox_id": this.userJobs[0].speciality[0].speciality_id,
                                    "title": "industry"
                                }
                            ]
                        }
                    }
                }
                await this.getAllJobsOrFilter(payload)
                this.isSearch = false

            }
            else {
                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: 1,
                    perPage: 10,
                    search: this.search,
                    filter: { filter: { filters: [] } }
                }
                await this.getAllJobsOrFilter(payload)

                this.isSearch = true
                this.isDesktop = true
                this.isMobileMenu = window.innerWidth <= 992
            }
        },
        removeDuplicatesSkills(data) {
            const uniqueSkills = {};
            const result = [];

            // Iterate through the types array
            data.forEach(type => {
                // Check if the skill_id is unique
                if (!uniqueSkills[type.skill_id]) {
                    uniqueSkills[type.skill_id] = true;
                    result.push(type);
                }
            });

            return result;
        },
        async NavigateToInterview(job_id) {
            const userData = this.$store.getters.getLoggedUser
            console.log('userData', userData);
            if (!userData.isLoggedIn) {
                this.$router.push({ name: "Candidate-login" })

            } else {
                // const industry = this.$store.getters.getUsersIndustry
                //// console.log("industry", industry);
                // const skills = this.$store.getters.getUsersSkills
                //// console.log("skills", skills);

                const industryRes = await CandidateService.GetselectedIndustry(userData.userId)
                const selectedIndustry = industryRes.data.data.types
                const SkillsRes = await CandidateService.GetSelectedSkills(userData.userId)
                const SelectedSkills = this.removeDuplicatesSkills(SkillsRes.data.data.types)
                console.log('userData.userData.country_id', userData.userData.country_id);

                if (userData.userData.country_id === null) {
                    this.$router.push({ name: "Personal-Details" })
                }
                else if (!selectedIndustry.length) {
                    //console.log('selectedIndustry', selectedIndustry);
                    this.$router.push({ name: "Industry" })
                }
                else if (!SelectedSkills.length) {
                    //console.log('SelectedSkills', SelectedSkills);
                    this.$router.push({ name: "Industry" })
                }
                else {

                    this.$router.push({ name: 'Interview-Questions', job_id })
                }
            }


        },
        saveJob(job_id) {
            const payload = {
                user_id: this.$store.getters.getLoggedUser.userId,
                job_id
            }
            CandidateService.SaveJob(payload)
                .then((res) => {
                    //console.log(res.data.data.bookmark_active);
                    if (res.data.data.bookmark_active === 'Y') {
                        this.userJobs[0].isSaved = true
                        this.$toasted.show(`Job added to saved list`, {
                            duration: 6000,
                            type: "success",
                            icon: "check",
                        });
                    }
                    else {
                        this.userJobs[0].isSaved = false
                        this.$toasted.show(`Job removed from saved list`, { duration: 6000 });
                    }
                })
                .catch((error) => {
                    // this.$toasted.show(`Image extension not suppoted`, { duration: 6000 });
                    //console.log(error);
                })
        },
        async getAllCountry() {
            // MasterServices.GetAllCountry()
            //     .then((res) => {
            ////         console.log("res.data.data", res.data.data);
            //         this.CountryOption = res.data.data.length ? res.data.data : []
            ////         console.log("this.CountryOption", this.CountryOption);

            //     })
            //     .catch((error) => {
            ////         console.log(error);
            //     })
            try {
                const AllCountryRes = await MasterServices.GetAllCountry()
                return AllCountryRes.data.data
            } catch (error) {
                //console.log(error);
            }
        },
        async GetAllCityOfCountry(country_id) {
            //console.log("this.country", country_id);
            if (country_id !== null) {
                const payload = {
                    country_id: country_id
                }
                // MasterServices.GetAllCityOfCountry(payload).then((res) => {
                ////     console.log('City::', res);
                //     this.CityOption = res.data.data.map((city) => {
                //         return {
                //             label: city.city_name,
                //             city_id: city.city_id,
                //         };
                //     });

                // });
                try {
                    const cityRes = await MasterServices.GetAllCityOfCountry(payload)
                    this.cityOptions = cityRes.data.data.map((city) => {
                        return {
                            label: city.city_name,
                            city_id: city.city_id,
                        };
                    });

                } catch (error) {
                    //console.log("city::error", error);
                }
            }
        },
        async getAllIndustry() {
            try {
                const AllIndustryRes = await MasterServices.GetAllIndustry()
                //console.log("AllIndustryRes", AllIndustryRes.data.data.types);
                return AllIndustryRes.data.data.types;
            } catch (error) {

            }
        },
        async getAllSavedJobs() {
            try {
                const SavedJobsRes = await CandidateService.GetAllSavedJobs()
                //console.log("SavedJobsRes", SavedJobsRes);
                return SavedJobsRes.data.data;
            } catch (error) {

            }
        },
        async GetMasterData() {
            this.countryOptions = await this.getAllCountry()
            this.industryOptions = await this.getAllIndustry()
            if (this.$store.getters.getLoggedUser.userId !== null) {

                this.allSavedJobs = await this.getAllSavedJobs()
            }
        },
        async PreFilledData(job_id) {
            // this.goBack()
            const loader = this.$loading.show()
            await this.GetMasterData()
            const userData = this.$store.getters.getLoggedUser
            const payload = {
                job_id: job_id,
                user_id: userData.userId,
                role_id: null,
            }
            CandidateService.GetJobsByID(payload)
                .then(async (res) => {
                    //console.log("Jobs Detail::res.data", res.data.data);
                    //console.log("SavedJobs Detail::res.data", this.allSavedJobs);
                    const JobsRes = res.data.data
                    const JobDerails = []
                    JobsRes.map((sj) => {
                        const apJob = {
                            isApplied: sj.applied,
                            job_id: sj.job_id,
                            company_logo_path: sj.company_logo_path,
                            Job_title: sj.job_title,
                            company: sj.company_long_name,
                            jobType: sj.job_type,
                            postedAt: convertToTimeSince(sj.created_at),
                            total_applied: sj.total_applicant,
                            numberOfOpenings: sj.vacancy,
                            job_salary_range_from: formattedNumber(sj.job_salary_range_from),
                            job_salary_range_to: formattedNumber(sj.job_salary_range_to),
                            gender: sj.gender === "Female" ? "F" : "M",
                            jobDescription: sj.job_description,
                            qualification: sj.edu.length ? sj.edu : [],
                            responsibilities: sj.resp.length ? sj.resp : [],
                            skills: sj.skills.length ? sj.skills : [],
                            education: sj.edu.length ? sj.edu : [],
                            job_experiance: sj.job_experiance !== null ? sj.job_experiance : null,
                            speciality: this.industryOptions.filter((s) => s.speciality_id === sj.speciality_id),
                            // designation: "Senior level",
                            country: sj.country[0].country_name,
                            city: sj.city[0].city_name,
                            currency: sj.currency_code,
                            nationality: sj.nationality.length ? sj.nationality : [],
                            job_end_date: sj.job_end_date.split('T')[0],//convertToTimeSince(sj.job_end_date),
                            isSaved: this.allSavedJobs.length ? Boolean(this.allSavedJobs.filter(item => item.job_id === sj.job_id).length) : false
                        }
                        JobDerails.push(apJob)
                    })

                    this.userJobs = JobDerails
                    //console.log(this.userJobs)
                    const payload = {
                        role_id: null,
                        user_id: null,
                        currentpage: 1,
                        perPage: 10,
                        search: '',
                        filter: {
                            filter: {
                                filters: [
                                    {
                                        "checkbox": this.userJobs[0].speciality[0].speciality,
                                        "checkbox_id": this.userJobs[0].speciality[0].speciality_id,
                                        "title": "industry"
                                    }
                                ]
                            }
                        }
                    }
                    await this.getAllJobsOrFilter(payload)
                })
                .catch((error) => {
                    //console.log(error);
                })
            loader.hide()
        },

        share() {
            this.isShare = true
        },

        handleSubmit() {
            this.isShare = false
        },
        openBottomSheet() {
            //console.log('this.homeFilter');
            this.homeFilter = true;
        },
        closeBottomSheet() {
            this.homeFilter = false;
        },
        handleClickOutside(e) {
            if (this.homeFilter) {
                //// console.log('e.target', e.target);
                //// console.log('e.target', e.target.className);
                const constbtContent = document.getElementById('bt-content')
                const filterbtn = document.getElementById('filter-btn1')
                const nav = document.getElementById('zc-nav')
                const industry = document.getElementById('industry-jd')
                //// console.log('constbtContent', constbtContent);
                console.log('e.target.contains(nav)', nav.contains(e.target));
                const btn = e.target.contains(constbtContent) || constbtContent.contains(e.target) || constbtContent.contains(industry)
                console.log(btn);
                if (e.target.className === 'zcBottomSheet' || !btn || nav.contains(e.target)) {
                    this.hideMenu()
                }
            }
        },
        hideMenu: function () {
            this.homeFilter = false
        },
    },
    created() {
        // addEventListener("resize", (e) => {
        //     this.isMobileMenu = window.innerWidth <= 992
        // });
    },
    mounted() {
        window.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener("click", this.handleClickOutside);
    },
    async beforeMount() {
        const job_id = this.$route.params.job_id;
        //console.log('job_id', job_id);
        // CandidateService.GetJobsByID

        // this.PreFilledData(job_id)
        this.goBack(job_id)

    },




}
</script>

<style>
.job-op-sal {
    display: flex;
    justify-content: space-between;
}

.job-currency {
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: 4px;
    margin-left: 57px;
}

.zc-job-left {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.zc-border-left {
    border-left: 1px solid #E9E6E6;
}

.divider {
    width: 100%;
    height: 0px;
    border: 1px solid rgb(233, 230, 230);

}

.divider-mt {
    margin: 0 0 0 -9px;
}

.zc-divider-mx {}

@media screen and (max-width:992px) {
    .mob-flex-reverse {
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .divider-mt {
        margin: 0 0 0 -4px;
    }

    .div-order-2 {
        order: 2;
    }

    .zc-mt-1 {
        margin-top: 11px !important;
    }

    .zc-ml-4 {
        font-weight: 600;
        margin-left: 4.1rem;
        margin-top: 0.3rem;
        margin-bottom: .3rem;
    }

    /* .zc-job-left {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 0 !important;
    } */

    .zc-job-left {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 0 !important;
        margin-left: -15px;
        text-wrap: nowrap;
        align-items: center;
    }

    .zc-border-left {
        border-left: none;
    }



    .zcModal .zcModal-content .social-icons-grid {
        display: flex;
        color: black;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 38px 0 38px;
        align-items: center;
    }

    .social-icons-grid a {
        color: black;
    }

    .social-icons-grid p {
        margin-bottom: 2px;
    }

    .social-icons-grid .fa {
        font-size: 20px;
    }
}
</style>